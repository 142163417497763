@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat:ital,wght@0,400;1,300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .bannerIcon {
        @apply w-8 h-8 lgl:w-10 lgl:h-10 bg-cyan-900 bg-opacity-40 text-gray-200 text-xl inline-flex items-center justify-center rounded-md
        shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-designColor cursor-pointer
        duration-300;
    }

    .bannerDoctoIcon {
        @apply w-14 lg:w-24 p-2 bg-cyan-900 bg-opacity-40 text-gray-200 text-xl items-center justify-center rounded-md
        shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-designColor cursor-pointer
    }
}
.scrollbar-hide::-webkit-scrollbar{
    display: none;
}